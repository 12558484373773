import React from "react";

export const Navigation = (props) => {
  return (
      <nav id="menu" class="navbar navbar-default navbar-fixed-top">
        <div class="container-fluid">
          <div class="navbar-header">
            <a class="navbar-brand" href="#page-top">
              <img src="/img/logo.png" alt="logo"/>
              <span>HYLINK TECHNOLOGIES</span>
            </a>
            <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#bs-navbar-collapse">
              <span class="sr-only">Toggle navigation</span>
              <span class="icon-bar"></span>
              <span class="icon-bar"></span>
              <span class="icon-bar"></span>
            </button>
          </div>
          <div class="collapse navbar-collapse" id="bs-navbar-collapse">
            <ul class="nav navbar-nav ms-auto navbar-right" >
              <li><a href="#services" class="page-scroll">Services</a></li>
              <li><a href="#partners" class="page-scroll">Partners</a></li>
              <li><a href="#about" class="page-scroll">About</a></li>
              <li><a href="#contact" class="page-scroll">Contact</a></li>
            </ul>
          </div>
        </div>
      </nav>
  );
};
