import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";
import { Button, Modal } from 'antd';
import React, { useState } from "react";

// Default theme
import '@splidejs/react-splide/css';

export const Partners = (props) => {
  const [isCylinkModalOpen, setIsCylinkModalOpen] = useState(false);
  const [isSNModalOpen, setIsSNModalOpen] = useState(false);
  const [isValidityModalOpen, setIsValidityModalOpen] = useState(false);
  const [isSevanModalOpen, setIsSevanModalOpen] = useState(false);
  const [isTriisModalOpen, setIsTriisModalOpen] = useState(false);
  const [isPhoenixModalOpen, setIsPhoenixModalOpen] = useState(false);
  const [isWWModalOpen, setIsWWModalOpen] = useState(false);
  const [isIDEXModalOpen, setIsIDEXModalOpen] = useState(false);
  const [isDTXModalOpen, setIsDTXModalOpen] = useState(false);
  const [isNokNokModalOpen, setIsNokNokModalOpen] = useState(false);

  const showModal = (setMethod, e) => {
    e.currentTarget.blur();
    setMethod(true);
  };

  const closeModal = (setMethod) => {
    setMethod(false);
  };

  return (
    <div id="partners">
      <div style={{ margin: '0 75px', }}>
        <div className="section-title text-center">
          <h2>Partners</h2>
        </div>
        <Splide hasTrack={false}
          options={{
            type: 'loop',
            perPage: 6,
            fixedHeight: '250px',
            drag: "free",
            autoScroll: {
              pauseOnHover: true,
              pauseOnFocus: true,
              rewind: false,
              speed: 1
            },
            pagination: false,
            arrows: false,
            breakpoints: {
              1435: {
                perPage: 4
              },
              970: {
                perPage: 3
              },
              780: {
                perPage: 2,
              },
              430: {
                gap: 125,
                perPage: 2,
              }
            }
          }}
          extensions={{ AutoScroll }}
          aria-label="Partners">
          <SplideTrack>
            <SplideSlide>
              <div className="partner-image" title="Cylink">
                <Button type="link" onClick={(e) => showModal(setIsCylinkModalOpen, e)} className="w-100 h-100">
                  <img src="/img/partners/cylink.png" alt="Cylink" style={{ width: '150px' }} />
                </Button>
              </div>
            </SplideSlide>
            <SplideSlide>
              <div className="partner-image" title="SafeNet">
                <Button type="link" onClick={(e) => showModal(setIsSNModalOpen, e)} className="w-100 h-100">
                  <img src="/img/partners/safenet.png" alt="SafeNet" style={{ width: '200px' }} />
                </Button>
              </div>
            </SplideSlide>
            <SplideSlide>
              <div className="partner-image" title="SafeNet">
                <Button type="link" onClick={(e) => showModal(setIsValidityModalOpen, e)} className="w-100 h-100">
                  <img src="/img/partners/validity.gif" alt="Validity" style={{ width: '220px' }} />
                </Button>
              </div>
            </SplideSlide>
            <SplideSlide>
              <div className="partner-image" title="Sevan Networks">
                <Button type="link" onClick={(e) => showModal(setIsSevanModalOpen, e)} className="w-100 h-100">
                  <img src="/img/partners/sevannetworks.gif" alt="Sevan Networks" style={{ width: '200px' }} />
                </Button>
              </div>
            </SplideSlide>
            <SplideSlide>
              <div className="partner-image" title="TRIiS Solution">
                <Button type="link" onClick={(e) => showModal(setIsTriisModalOpen, e)} className="w-100 h-100">
                  <img src="/img/partners/TRIiSSolution.gif" alt="TRIiS Solution" style={{ width: '200px' }} />
                </Button>
              </div>
            </SplideSlide>
            <SplideSlide>
              <div className="partner-image" title="Phoenix">
                <Button type="link" onClick={(e) => showModal(setIsPhoenixModalOpen, e)} className="w-100 h-100">
                  <img src="/img/partners/phoenix.png" alt="Phoenix" style={{ width: '200px' }} />
                </Button>
              </div>
            </SplideSlide>
            <SplideSlide>
              <div className="partner-image" title="Wave Wireless">
                <Button type="link" onClick={(e) => showModal(setIsWWModalOpen, e)} className="w-100 h-100">
                  <img src="/img/partners/wavewireless.gif" alt="Wave Wireless" style={{ width: '200px' }} />
                </Button>
              </div>
            </SplideSlide>
            <SplideSlide>
              <div className="partner-image" title="Nok Nok Labs">
                <Button type="link" onClick={(e) => showModal(setIsNokNokModalOpen, e)} className="w-100 h-100">
                  <img src="/img/partners/noknok.png" alt="Nok Nok Labs" style={{ width: '150px' }} />
                </Button>
              </div>
            </SplideSlide>
            <SplideSlide>
              <div className="partner-image" title="IDEX">
                <Button type="link" onClick={(e) => showModal(setIsIDEXModalOpen, e)} className="w-100 h-100">
                  <img src="/img/partners/idex.png" alt="IDEX" style={{ width: '200px' }} />
                </Button>
              </div>
            </SplideSlide>
            <SplideSlide>
              <div className="partner-image" title="Digital Trust Networks">
                <Button type="link" onClick={(e) => showModal(setIsDTXModalOpen, e)} className="w-100 h-100">
                  <img src="/img/partners/dtx.png" alt="Digital Trust Networks" style={{ width: '200px' }} />
                </Button>
              </div>
            </SplideSlide>
          </SplideTrack>
        </Splide>

        <Modal
          title="Cylink Corporation"
          centered
          okType="default"
          onOk={() => closeModal(setIsCylinkModalOpen)}
          open={isCylinkModalOpen}
          destroyOnClose={true}
          onCancel={() => closeModal(setIsCylinkModalOpen)}
          cancelButtonProps={{ style: { display: 'none' } }}>
          <br />
          <p>
            Headquartered in Santa Clara, California, Cylink develops, markets, and supports a comprehensive portfolio of hardware and software security products for mission-critical private networks and business communications over the Internet. The company's solutions offer competitive advantages by lowering the cost of deploying and managing secure, reliable private networks, while enabling the use of the Internet for trusted transactions with business partners and customers.
          </p>
          <a href="https://www.cylink.com">www.cylink.com</a>
        </Modal>
        <Modal
          title="SafeNet, Inc."
          centered
          okType="default"
          onOk={() => closeModal(setIsSNModalOpen)}
          open={isSNModalOpen}
          onCancel={() => closeModal(setIsSNModalOpen)}
          destroyOnClose={true}
          cancelButtonProps={{ style: { display: 'none' } }}>
          <br />
          <p>
            SafeNet has set the industry standard for virtual private network (VPN) technology and secure business communications. With more than 18 years experience in developing, deploying, and managing network security systems for the most security-conscious financial institutions and government organizations around the world, SafeNet's proven technology has emerged as the de facto industry standard for VPNs. With SafeNet securing the infrastructure of today's e-business communications as well as leading the way in government Homeland and classified data security, the company has opened new markets for interoperable, secure, and deployable VPN communications. Commercial customers include Texas Instruments, Microsoft, Samsung, Centillium Communications, ARM, and Cisco Systems. Government customers include U.S. Department of Defense, U.S. Internal Revenue Service, and the Social Security Administration.
          </p>
          <a href="https://www.safenet-inc.com">www.safenet-inc.com</a>
        </Modal>
        <Modal
          title="Validity Sensors, Inc."
          centered
          okType="default"
          onOk={() => closeModal(setIsValidityModalOpen)}
          open={isValidityModalOpen}
          destroyOnClose={true}
          onCancel={() => closeModal(setIsValidityModalOpen)}
          cancelButtonProps={{ style: { display: 'none' } }}>
          <br />
          <p>
            Headquartered in San Jose, California, with facilities in Phoenix, Arizona and Boxborough, Massachusetts, Validity Sensors, Inc. is a provider of the world's most durable, high performance finger print sensors for the PC & Networking, Wireless, Portable Storage and Access Control markets.
            <br />
            Validity's biometric security products enable a wide range of product enhancements across numerous market segments including password replacement for PCs and applications, centralized management for corporate network security, secure mobile transactions, protection of portable data, identity verification for government and military applications and physical access control.
          </p>
          <a href="https://www.validitysensors.com">www.validitysensors.com</a>
        </Modal>
        <Modal
          title="Sevan Networks"
          centered
          okType="default"
          onOk={() => closeModal(setIsSevanModalOpen)}
          open={isSevanModalOpen}
          destroyOnClose={true}
          onCancel={() => closeModal(setIsSevanModalOpen)}
          cancelButtonProps={{ style: { display: 'none' } }}>
          <br />
          <p>
            Sevan Networks, Inc. is an innovative provider of web security solutions. Our product integrates strong security directly into the Web to reduce the security risks of web applications. It is good security and it is simple.
          </p>
          <a href="https://www.sevannetworks.com">www.sevannetworks.com</a>
        </Modal>
        <Modal
          title="TRIiS Solution (former OmniTrust Inc., former Probix)"
          centered
          okType="default"
          onOk={() => closeModal(setIsTriisModalOpen)}
          open={isTriisModalOpen}
          destroyOnClose={true}
          onCancel={() => closeModal(setIsTriisModalOpen)}
          cancelButtonProps={{ style: { display: 'none' } }}>
          <br />
          <a href="https://www.crearia.co.jp">www.crearia.co.jp</a>
        </Modal>
        <Modal
          title="Phoenix Technologies"
          centered
          okType="default"
          onOk={() => closeModal(setIsPhoenixModalOpen)}
          open={isPhoenixModalOpen}
          destroyOnClose={true}
          onCancel={() => closeModal(setIsPhoenixModalOpen)}
          cancelButtonProps={{ style: { display: 'none' } }}>
          <br />
          <p>
            Phoenix Technologies builds world-class core system software platforms, tools and applications that take full advantage of our history of expertise at the core of digital devices, to enable the broadest set of users a more simple, trusted and reliable experience.
          </p>
          <a href="https://www.phoenix.com">www.phoenix.com</a>
        </Modal>

        <Modal
          title="Wave Wireless"
          centered
          okType="default"
          onOk={() => closeModal(setIsWWModalOpen)}
          open={isWWModalOpen}
          destroyOnClose={true}
          onCancel={() => closeModal(setIsWWModalOpen)}
          cancelButtonProps={{ style: { display: 'none' } }}>
          <br />
          <p>
            Wave Wireless (formerly P-Com) sells wireless distribution equipment used in the point-to-point, point-to-multipoint, and mesh wireless networks of telecom companies, corporations, and government entities. Its SPEEDLAN line of mesh routers (acquired from SPEEDCOM in 2003) constitutes its main product offering, following a 2005 restructuring that included discontinuing its wireless backhaul equipment lines.
          </p>
        </Modal>
        <Modal
          title="Nok Nok Labs"
          centered
          okType="default"
          onOk={() => closeModal(setIsNokNokModalOpen)}
          open={isNokNokModalOpen}
          destroyOnClose={true}
          onCancel={() => closeModal(setIsNokNokModalOpen)}
          cancelButtonProps={{ style: { display: 'none' } }}>
          <br />
          <p>
            Nok Nok Labs is backed by veterans of the security industry from PGP, Netscape, PayPal & Phoenix and have deep experience in building Internet scale security protocols and products. <br /> <br />
            Based in Palo Alto, CA, the company was founded in November 2011 to unify the silos that plague current online authentication technologies.
          </p>
          <a href="https://www.noknok.com">www.noknok.com</a>
        </Modal>
        <Modal
          title="IDEX"
          centered
          okType="default"
          onOk={() => closeModal(setIsIDEXModalOpen)}
          open={isIDEXModalOpen}
          destroyOnClose={true}
          onCancel={() => closeModal(setIsIDEXModalOpen)}
          cancelButtonProps={{ style: { display: 'none' } }}>
          <br />
          <p>
            IDEX ASA (ticker IDEX) is a Norwegian public company listed at the Oslo Axess market place on the Oslo Børs (Oslo stock exchange). IDEX ASA specialises in fingerprint imaging and recognition technology. The company has a strong patent portfolio consisting of more than 25 patent families including more than 215 granted patents, as well as cross licenses with Apple.
          </p>
          <a href="https://www.idex.no">www.idex.no</a>
        </Modal>
        <Modal
          title="Digital Trust Networks, Inc"
          centered
          okType="default"
          onOk={() => closeModal(setIsDTXModalOpen)}
          open={isDTXModalOpen}
          destroyOnClose={true}
          onCancel={() => closeModal(setIsDTXModalOpen)}
          cancelButtonProps={{ style: { display: 'none' } }}>
          <br />
          <p>
            Digital Trust Networks is a digital world firm that offers a paradigm for identity and authentication.
          </p>
          <a href="https://www.digitaltrustnetworks.com">www.digitaltrustnetworks.com</a>
        </Modal>
      </div>
    </div>
  );
};
