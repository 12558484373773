import React from "react";
import { Fade } from "react-awesome-reveal";

export const About = (props) => {
  return (
    <div id="about">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-4">
            {" "}
            <img src="img/about.jpg" className="img-responsive" alt="" />{" "}
          </div>
          <div className="col-xs-12 col-md-8">
            <div className="about-text">
              <Fade>
                <h2>About Us</h2>
              </Fade>
              <Fade>
                <p>We are Hylink Technologies LLC (former Hylink JSC), a private company headquartered in Armenia, working since 1992. We have been providing software and hardware research, development and consultation services exclusively to the companies from the USA. Our main area of expertise is network security and currently we are specializing in the area of user identification and the client authentication. <br /> <br /> Hylink Technologies LLC and Intellcores Ltd provide high quality engineering and project management services to help companies in product development lifecycle.</p>
                <p>Today Hylink Technologies LLC and Intellcores Ltd employs around 50 engineers and researchers. Being highly selective in hiring the engineers we find very capable, motivated and dependable individuals and let them flourish. Our devotion in partnership leads to long-term relationships with our partners that go beyond typical outsourcing. Armenian team supplements and becomes an integral part of your research and development team. Both sides benefit from it and that makes all the difference in the world of outsourcing.</p>
              </Fade>
              <Fade>
                <h3>Why Choose Us?</h3>
              </Fade>
              <div className="list-style">
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>
                    {props.aboutData
                      ? props.aboutData.Why.map((d, i) => (
                        <Fade>
                          <li key={`${d}-${i}`}>{d}</li>
                        </Fade>
                      ))
                      : "loading"}
                  </ul>
                </div>
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>
                    {props.aboutData
                      ? props.aboutData.Why2.map((d, i) => (
                        <Fade>
                          <li key={`${d}-${i}`}> {d}</li>
                        </Fade>
                      ))
                      : "loading"}
                  </ul>
                </div>
              </div>
            </div>
            <div id="team">
              <Fade>
                <h2 style={{ marginTop: '130px' }}>Management</h2>
              </Fade>
              {props.teamData
                ? props.teamData.map((d, i) => (
                  <div key={`${d.name}-${i}`} className="col-md-12 col-sm-6 team">
                    <Fade>
                      <div className="thumbnail">
                        {" "}
                        <div className="caption">
                          <h4>{d.name}</h4>
                          <p>{d.job}</p>
                        </div>
                      </div>
                    </Fade>
                  </div>
                ))
                : "loading"}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
