import React from "react";
import { Fade } from "react-awesome-reveal";

export const Header = (props) => {
  return (
      <header id="header" style={{marginTop: '80px'}}>
        <div className="intro">
          <div className="overlay">
            <div className="container">
              <div className="row">
                <div className="col-md-8 col-md-offset-2 intro-text">
                  <Fade>
                    <p style={{ fontStyle:'italic', fontSize:'24px', fontWeight:'700' }}>"Your Ultimate Solution Partner. We deliver innovative and tailored solutions to meet your unique needs. Trust us to drive your success with our expertise and commitment..."</p>
                    <a href="#services" className="btn btn-custom btn-lg page-scroll" >Learn More</a>{" "}
                  </Fade>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
  );
};
